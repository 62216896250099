<template>
	<section class="">
		<article v-for="(response, index) in responses" :key="response.id">
			<h4>RESPONSE #{{ index + 1 }}</h4>
			<InputTextArea v-model="response.responseText"
				><template #label>Response Text</template></InputTextArea
			>
			<figure class="d-flex flex-row align-center justify-space-between">
				<InputTextField v-model="response.score"
					><template #label>Score</template></InputTextField
				>
				<v-checkbox
					v-model="response.isCorrect"
					label="This is true"
					color="black"
					class="mt-4"
				></v-checkbox>

				<!-- :id="input.id"
				:value="input.id" -->
				<!-- <v-btn
					:disabled="true"
					class="admin-default-transparent-button primary-text"
					>Add to response bank</v-btn
				> -->
			</figure>
		</article>
	</section>
</template>

<script>
import InputTextField from "@/components/InputTextField.vue";

export default {
	name: "NewQuestionResponseMultipleChoices",
	components: { InputTextField },
	props: {
		responses: {
			type: Array,
			require: true
		}
	},
	computed: {},
	data() {
		return {};
	},
	methods: {}
};
</script>

<style></style>
